import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import "../css/UserTable.css";
import UserService from "../app/service/UserService";
import { Button } from "react-bootstrap";
import ChangePasswordModal from "./ChangePasswordModal";
import UserModal from "./UserModal";
export default function UserTable({ isDarkMode }) {
  const userService = new UserService();
  const [users, setUsers] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [show, setShow] = useState(false);
  const [editExtension, setEditExtension] = useState("");
  const [showUserModal, setShowUserModal] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    const link = document.createElement("link");
    link.id = "theme-link";
    link.rel = "stylesheet";
    if (isDarkMode) {
      link.href = "./css/DarkTable.css";
    } else {
      link.href = "./css/LightTable.css";
    }
    document.head.appendChild(link);

    return () => {
      const existingLink = document.getElementById("theme-link");
      if (existingLink) {
        document.head.removeChild(existingLink);
      }
    };
  }, [isDarkMode]);

  const getData = () => {
    userService.listUsers().then((response) => setUsers(response.data));
  };

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    getData();
  }, []);

  const toggleUserModal = (test, extension) => {
    setEdit(test);
    if (test) {
      setEditExtension(extension);
    }

    setShowUserModal((prev) => !prev);
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setFilters({
      ...filters,
      global: { value, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <Button
          onClick={() => {
            toggleUserModal(false);
          }}
        >
          Criar Usuário
        </Button>
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Pesquisar"
          className="p-inputtext"
        />
      </div>
    );
  };

  const toggleStatus = (extension) => {
    userService.toggleStatus(extension).then(() => getData());
  };

  const header = renderHeader();

  const statusBody = (rowData) => {
    const variant = rowData.status === true ? "success" : "danger";
    const icon =
      rowData.status === true ? (
        <i className="fa-solid fa-check"></i>
      ) : (
        <i className="fa-solid fa-x"></i>
      );
    return (
      <Button variant={variant} onClick={() => toggleStatus(rowData.extension)}>
        {icon}
      </Button>
    );
  };

  const passwordBody = (rowData) => (
    <Button onClick={() => handlePasswordChange(rowData.extension)}>
      <i className="fa-solid fa-key"></i>
    </Button>
  );
  const toggleModal = () => {
    setShow((prev) => !prev);
  };

  const handlePasswordChange = (extension) => {
    setEditExtension(extension);
    toggleModal();
  };

  const editBody = (rowData) => (
    <Button onClick={() => toggleUserModal(true, rowData.extension)}>
      <i className="fa-solid fa-pencil"></i>
    </Button>
  );

  return (
    <div className="card" style={{ marginBottom: "50px" }}>
      <DataTable
        value={users}
        filters={filters}
        paginator
        rows={10}
        dataKey="extension"
        header={header}
        emptyMessage="Nenhum usuário encontrado!"
      >
        <Column field="name" header="Nome" />
        <Column field="extension" header="Ramal" />
        <Column field="username" header="Usuário" />
        <Column field="status" header="Status" body={statusBody} />
        <Column header="Senha" body={passwordBody} />
        <Column header="Editar" body={editBody} />
      </DataTable>
      <ChangePasswordModal
        show={show}
        toggle={toggleModal}
        isDarkMode={isDarkMode}
        adminChange={true}
        extension={editExtension}
      />
      <UserModal
        show={showUserModal}
        toggleModal={toggleUserModal}
        edit={edit}
        isDarkMode={isDarkMode}
        editExtension={editExtension}
        getData={getData}
      />
    </div>
  );
}
