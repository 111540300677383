import React, { useEffect, useState } from "react";
import { Button, Form, FormControl, Modal } from "react-bootstrap";
import { MultiSelect } from "primereact/multiselect";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "../css/TaskModal.css";
import UserService from "../app/service/UserService";
export default function TasksModal({
  show,
  isDarkMode,
  toggleModal,
  createTasks
}) {
  const [options, setOptions] = useState([]);
  const userService = new UserService();
  const [sip1] = useState("PLATAFORMA SIP1 - 129");
  const [selectedOptionsSip1, setSelectedOptionsSip1] = useState([]);
  const [deadlineSip1, setDeadlineSip1] = useState("");
  const [sip2] = useState("PLATAFORMA SIP2 - 35");
  const [selectedOptionsSip2, setSelectedOptionsSip2] = useState([]);
  const [deadlineSip2, setDeadlineSip2] = useState("");
  const [sip3] = useState("PLATAFORMA SIP3 - 164");
  const [selectedOptionsSip3, setSelectedOptionsSip3] = useState([]);
  const [deadlineSip3, setDeadlineSip3] = useState("");
  const [sip4] = useState("PLATAFORMA SIP4 - 181");
  const [selectedOptionsSip4, setSelectedOptionsSip4] = useState([]);
  const [deadlineSip4, setDeadlineSip4] = useState("");
  const [blp] = useState("ROTAS BLP");
  const [selectedOptionsBlp, setSelectedOptionsBlp] = useState([]);
  const [deadlineBlp, setDeadlineBlp] = useState("");
  const [itx] = useState("ROTAS ITX");
  const [selectedOptionsItx, setSelectedOptionsItx] = useState([]);
  const [deadlineItx, setDeadlineItx] = useState("");
  const [gateway] = useState("GATEWAY");
  const [selectedOptionsGateway, setSelectedOptionsGateway] = useState([]);
  const [deadlineGateway, setDeadlineGateway] = useState("");
  const [latency] = useState("LATÊNCIA");
  const [selectedOptionsLatency, setSelectedOptionsLatency] = useState([]);
  const [deadlineLatency, setDeadlineLatency] = useState("");
  const [disc] = useState("ESPAÇO EM DISCO PABX");
  const [selectedOptionsDisc, setSelectedOptionsDisc] = useState([]);
  const [deadlineDisc, setDeadlineDisc] = useState("");
  
  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    getUsers();
  }, []);

  useEffect(() => {
    const link = document.createElement("link");
    link.id = "theme-link";
    link.rel = "stylesheet";
    link.href = isDarkMode ? "./css/DarkTable.css" : "./css/LightTable.css";
    document.head.appendChild(link);

    return () => {
      const existingLink = document.getElementById("theme-link");
      if (existingLink) {
        document.head.removeChild(existingLink);
      }
    };
  }, [isDarkMode]);

  const getUsers = async () => {
    try {
      const response = await userService.listEnabledUsers();
      setOptions(
        response.data.map((user) => ({
          label: user.name,
          value: user.extension,
        }))
      );
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

const handleCreateTasks = () => {
  var requestBody = [
    {
      title: sip1,
      deadline: deadlineSip1,
      owners: selectedOptionsSip1,
    },
    {
      title: sip2,
      deadline: deadlineSip2,
      owners: selectedOptionsSip2,
    },
    {
      title: sip3,
      deadline: deadlineSip3,
      owners: selectedOptionsSip3,
    },
    {
      title: sip4,
      deadline: deadlineSip4,
      owners: selectedOptionsSip4,
    },
    {
      title: blp,
      deadline: deadlineBlp,
      owners: selectedOptionsBlp,
    },
    {
      title: itx,
      deadline: deadlineItx,
      owners: selectedOptionsItx,
    },
    {
      title: gateway,
      deadline: deadlineGateway,
      owners: selectedOptionsGateway,
    },
    {
      title: latency,
      deadline: deadlineLatency,
      owners: selectedOptionsLatency,
    },
    {
      title: disc,
      deadline: deadlineDisc,
      owners: selectedOptionsDisc,
    },
  ]

  createTasks(requestBody);
}

  return (
    <Modal
      show={show}
      size="xl"
      data-bs-theme={isDarkMode ? "dark" : "light"}
      style={{ color: isDarkMode ? "white" : "" }}
      onHide={toggleModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Tarefa</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <div className="col-3">
              <Form.Group controlId="taskName">
                <Form.Label>Tarefa</Form.Label>
                <FormControl
                  style={{ height: "43px" }}
                  className="modal-input"
                  readOnly
                  value={sip1}
                />
              </Form.Group>
            </div>
            <div className="col-3">
              <Form.Group controlId="deadline">
                <Form.Label>Prazo</Form.Label>
                <FormControl
                  type="datetime-local"
                  placeholder="Insira o prazo"
                  style={{ height: "43px" }}
                  className="modal-input"
                  value={deadlineSip1}
                  onChange={(e) => setDeadlineSip1(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group
                controlId="multiSelect"
                className="d-flex flex-column"
              >
                <Form.Label>Responsável(eis)</Form.Label>
                <MultiSelect
                  className="custom-multiselect"
                  value={selectedOptionsSip1}
                  options={options}
                  onChange={(e) => setSelectedOptionsSip1(e.value)}
                  placeholder="Selecione opções"
                  display="chip"
                />
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <div className="col-3">
              <Form.Group controlId="taskName">
                <Form.Label>Tarefa</Form.Label>
                <FormControl
                  style={{ height: "43px" }}
                  className="modal-input"
                  readOnly
                  value={sip2}
                />
              </Form.Group>
            </div>
            <div className="col-3">
              <Form.Group controlId="deadline">
                <Form.Label>Prazo</Form.Label>
                <FormControl
                  type="datetime-local"
                  placeholder="Insira o prazo"
                  style={{ height: "43px" }}
                  className="modal-input"
                  value={deadlineSip2}
                  onChange={(e) => setDeadlineSip2(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group
                controlId="multiSelect"
                className="d-flex flex-column"
              >
                <Form.Label>Responsável(eis)</Form.Label>
                <MultiSelect
                  className="custom-multiselect"
                  value={selectedOptionsSip2}
                  options={options}
                  onChange={(e) => setSelectedOptionsSip2(e.value)}
                  placeholder="Selecione opções"
                  display="chip"
                />
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <div className="col-3">
              <Form.Group controlId="taskName">
                <Form.Label>Tarefa</Form.Label>
                <FormControl
                  style={{ height: "43px" }}
                  className="modal-input"
                  readOnly
                  value={sip3}
                />
              </Form.Group>
            </div>
            <div className="col-3">
              <Form.Group controlId="deadline">
                <Form.Label>Prazo</Form.Label>
                <FormControl
                  type="datetime-local"
                  placeholder="Insira o prazo"
                  style={{ height: "43px" }}
                  className="modal-input"
                  value={deadlineSip3}
                  onChange={(e) => setDeadlineSip3(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group
                controlId="multiSelect"
                className="d-flex flex-column"
              >
                <Form.Label>Responsável(eis)</Form.Label>
                <MultiSelect
                  className="custom-multiselect"
                  value={selectedOptionsSip3}
                  options={options}
                  onChange={(e) => setSelectedOptionsSip3(e.value)}
                  placeholder="Selecione opções"
                  display="chip"
                />
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <div className="col-3">
              <Form.Group controlId="taskName">
                <Form.Label>Tarefa</Form.Label>
                <FormControl
                  style={{ height: "43px" }}
                  className="modal-input"
                  readOnly
                  value={sip4}
                />
              </Form.Group>
            </div>
            <div className="col-3">
              <Form.Group controlId="deadline">
                <Form.Label>Prazo</Form.Label>
                <FormControl
                  type="datetime-local"
                  placeholder="Insira o prazo"
                  style={{ height: "43px" }}
                  className="modal-input"
                  value={deadlineSip4}
                  onChange={(e) => setDeadlineSip4(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group
                controlId="multiSelect"
                className="d-flex flex-column"
              >
                <Form.Label>Responsável(eis)</Form.Label>
                <MultiSelect
                  className="custom-multiselect"
                  value={selectedOptionsSip4}
                  options={options}
                  onChange={(e) => setSelectedOptionsSip4(e.value)}
                  placeholder="Selecione opções"
                  display="chip"
                />
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <div className="col-3">
              <Form.Group controlId="taskName">
                <Form.Label>Tarefa</Form.Label>
                <FormControl
                  style={{ height: "43px" }}
                  className="modal-input"
                  readOnly
                  value={blp}
                />
              </Form.Group>
            </div>
            <div className="col-3">
              <Form.Group controlId="deadline">
                <Form.Label>Prazo</Form.Label>
                <FormControl
                  type="datetime-local"
                  placeholder="Insira o prazo"
                  style={{ height: "43px" }}
                  className="modal-input"
                  value={deadlineBlp}
                  onChange={(e) => setDeadlineBlp(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group
                controlId="multiSelect"
                className="d-flex flex-column"
              >
                <Form.Label>Responsável(eis)</Form.Label>
                <MultiSelect
                  className="custom-multiselect"
                  value={selectedOptionsBlp}
                  options={options}
                  onChange={(e) => setSelectedOptionsBlp(e.value)}
                  placeholder="Selecione opções"
                  display="chip"
                />
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <div className="col-3">
              <Form.Group controlId="taskName">
                <Form.Label>Tarefa</Form.Label>
                <FormControl
                  style={{ height: "43px" }}
                  className="modal-input"
                  readOnly
                  value={itx}
                />
              </Form.Group>
            </div>
            <div className="col-3">
              <Form.Group controlId="deadline">
                <Form.Label>Prazo</Form.Label>
                <FormControl
                  type="datetime-local"
                  placeholder="Insira o prazo"
                  style={{ height: "43px" }}
                  className="modal-input"
                  value={deadlineItx}
                  onChange={(e) => setDeadlineItx(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group
                controlId="multiSelect"
                className="d-flex flex-column"
              >
                <Form.Label>Responsável(eis)</Form.Label>
                <MultiSelect
                  className="custom-multiselect"
                  value={selectedOptionsItx}
                  options={options}
                  onChange={(e) => setSelectedOptionsItx(e.value)}
                  placeholder="Selecione opções"
                  display="chip"
                />
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <div className="col-3">
              <Form.Group controlId="taskName">
                <Form.Label>Tarefa</Form.Label>
                <FormControl
                  style={{ height: "43px" }}
                  className="modal-input"
                  readOnly
                  value={gateway}
                />
              </Form.Group>
            </div>
            <div className="col-3">
              <Form.Group controlId="deadline">
                <Form.Label>Prazo</Form.Label>
                <FormControl
                  type="datetime-local"
                  placeholder="Insira o prazo"
                  style={{ height: "43px" }}
                  className="modal-input"
                  value={deadlineGateway}
                  onChange={(e) => setDeadlineGateway(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group
                controlId="multiSelect"
                className="d-flex flex-column"
              >
                <Form.Label>Responsável(eis)</Form.Label>
                <MultiSelect
                  className="custom-multiselect"
                  value={selectedOptionsGateway}
                  options={options}
                  onChange={(e) => setSelectedOptionsGateway(e.value)}
                  placeholder="Selecione opções"
                  display="chip"
                />
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <div className="col-3">
              <Form.Group controlId="taskName">
                <Form.Label>Tarefa</Form.Label>
                <FormControl
                  style={{ height: "43px" }}
                  className="modal-input"
                  readOnly
                  value={latency}
                />
              </Form.Group>
            </div>
            <div className="col-3">
              <Form.Group controlId="deadline">
                <Form.Label>Prazo</Form.Label>
                <FormControl
                  type="datetime-local"
                  placeholder="Insira o prazo"
                  style={{ height: "43px" }}
                  className="modal-input"
                  value={deadlineLatency}
                  onChange={(e) => setDeadlineLatency(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group
                controlId="multiSelect"
                className="d-flex flex-column"
              >
                <Form.Label>Responsável(eis)</Form.Label>
                <MultiSelect
                  className="custom-multiselect"
                  value={selectedOptionsLatency}
                  options={options}
                  onChange={(e) => setSelectedOptionsLatency(e.value)}
                  placeholder="Selecione opções"
                  display="chip"
                />
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <div className="col-3">
              <Form.Group controlId="taskName">
                <Form.Label>Tarefa</Form.Label>
                <FormControl
                  style={{ height: "43px" }}
                  className="modal-input"
                  readOnly
                  value={disc}
                />
              </Form.Group>
            </div>
            <div className="col-3">
              <Form.Group controlId="deadline">
                <Form.Label>Prazo</Form.Label>
                <FormControl
                  type="datetime-local"
                  placeholder="Insira o prazo"
                  style={{ height: "43px" }}
                  className="modal-input"
                  value={deadlineDisc}
                  onChange={(e) => setDeadlineDisc(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group
                controlId="multiSelect"
                className="d-flex flex-column"
              >
                <Form.Label>Responsável(eis)</Form.Label>
                <MultiSelect
                  className="custom-multiselect"
                  value={selectedOptionsDisc}
                  options={options}
                  onChange={(e) => setSelectedOptionsDisc(e.value)}
                  placeholder="Selecione opções"
                  display="chip"
                />
              </Form.Group>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleCreateTasks}>Enviar</Button>
      </Modal.Footer>
    </Modal>
  );
}
