import { useEffect, useRef } from "react";
import Swal from "sweetalert2";

export default function NotificationAndDataUpdateAdmin({
  getData,
  isDashboard,
  notification,
  taskComponent,
}) {
  const socketRef = useRef(null);

  useEffect(() => {
    const connectWebSocket = () => {
      if (
        socketRef.current &&
        (socketRef.current.readyState === WebSocket.OPEN ||
          socketRef.current.readyState === WebSocket.CONNECTING)
      ) {
        return;
      }

      const newSocket = new WebSocket(
        "wss://apimonitoria.solutionsvoip.com.br/ws/my-endpoint"
      );

  

      newSocket.onmessage = (event) => {
        let message = event.data;

        if (message.includes("DataUpdate:")) {
          message = message.replace("DataUpdate:", "");
          Swal.fire({
            icon: "success",
            title: "Sucesso",
            text: message,
          }).then(() => {
            if (isDashboard) {
              getData();
            }
          });
        } else if (message.includes("Notification:")) {
          message = message.replace("Notification:", "");
          if (notification) {
            showNotification(message);
          }
        } else if (message.includes("TaskUpdate")) {
          if (taskComponent) {
            getData();
          }
        }
      };

      newSocket.onclose = (event) => {
        if (event.wasClean) {
       
        } else {
          setTimeout(connectWebSocket, 5000); // Tentar reconectar após 5 segundos
        }
      };

      newSocket.onerror = (error) => {
        newSocket.close();
      };

      socketRef.current = newSocket; // Guardar referência ao socket atual
    };

    connectWebSocket();

    return () => {
      if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
        socketRef.current.close();
      }
    };
  }, [getData, isDashboard, notification, taskComponent]);

  const showNotification = (bodyContent) => {
    if (Notification.permission === "granted") {
      new Notification("Grupo Solutions", {
        body: bodyContent,
      });
    }
  };

  useEffect(() => {
    if (Notification.permission === "default") {
      Notification.requestPermission().then((permission) => {
        if (permission === "denied") {
          console.warn("Notification permission denied");
        }
      });
    }
  }, []);

  return null;
}
