import ApiService from "../ApiService";
class CallService extends ApiService {
  constructor() {
    super("/chamada");
  }

  getCallGraphic(startDate, endDate) {
    return this.get("/graficoChamada/" + startDate + "/" + endDate);
  }

  getCalls(startDate, endDate) {
    return this.get("/" + startDate + "/" + endDate);
  }
}
export default CallService;
