import React, { useState, useEffect } from "react";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import "../css/PabxCallTable.css";
import CallService from "../app/service/CallService";
import NotificationAndDataUpdateAdmin from "./NotificationAndDataUpdateAdmin";

export default function PabxCallTable({ startDate, endDate, isDarkMode }) {
  const [calls, setCalls] = useState([]);
  const [filteredCalls, setFilteredCalls] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.EQUALS },
    origin: { value: null, matchMode: FilterMatchMode.EQUALS },
    destination: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [loading, setLoading] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [names, setNames] = useState([]);
  const [origins, setOrigins] = useState([]);
  const [destinations, setDestinations] = useState([]);

  const callService = new CallService();

  useEffect(() => {
    const link = document.createElement("link");
    link.id = "theme-link";
    link.rel = "stylesheet";
    if (isDarkMode) {
      link.href = "./css/DarkTable.css";
    } else {
      link.href = "./css/LightTable.css";
    }
    document.head.appendChild(link);

    return () => {
      const existingLink = document.getElementById("theme-link");
      if (existingLink) {
        document.head.removeChild(existingLink);
      }
    };
  }, [isDarkMode]);

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    getData();
  }, [startDate, endDate]);

  const getData = () =>{
    if (startDate !== "" && endDate !== "") {
      callService.getCalls(startDate, endDate).then((response) => {
        const data = response.data;
        setCalls(data);
        setFilteredCalls(data);
        setNames(getUniqueNames(data));
        setOrigins(getUniqueOrigins(data));
        setDestinations(getUniqueDestinations(data));
        setLoading(false);
      });
    }
  }

  useEffect(() => {
    // Atualiza os registros filtrados quando os filtros mudam
    const filtered = calls.filter((call) => {
      return Object.keys(filters).every((key) => {
        if (filters[key].value == null) return true;
        return String(call[key])
          .toLowerCase()
          .includes(String(filters[key].value).toLowerCase());
      });
    });
    setFilteredCalls(filtered);
  }, [filters, calls]);

  const getUniqueNames = (data) => {
    const names = [...new Set(data.map((item) => item.name))];
    return names.map((name) => ({ name: name, value: name }));
  };

  const getUniqueOrigins = (data) => {
    const origins = [...new Set(data.map((item) => item.origin))];
    return origins.map((origin) => ({ name: origin, value: origin }));
  };

  const getUniqueDestinations = (data) => {
    const destinations = [...new Set(data.map((item) => item.destination))];
    return destinations.map((destination) => ({
      name: destination,
      value: destination,
    }));
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setFilters({
      ...filters,
      global: { value, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue(value);
  };

  const onNameFilterChange = (e) => {
    const value = e.value || null;
    setFilters({
      ...filters,
      name: { value, matchMode: FilterMatchMode.EQUALS },
    });
  };

  const onOriginFilterChange = (e) => {
    const value = e.value || null;
    setFilters({
      ...filters,
      origin: { value, matchMode: FilterMatchMode.EQUALS },
    });
  };

  const onDestinationFilterChange = (e) => {
    const value = e.value || null;
    setFilters({
      ...filters,
      destination: { value, matchMode: FilterMatchMode.EQUALS },
    });
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <h3 style={{ margin: "0" }}>Chamadas realizadas</h3>
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Pesquisar"
          className="p-inputtext"
        />
      </div>
    );
  };

  const parseDate = (dateString) => {
    if (
      typeof dateString === "string" &&
      /^\d{4}-\d{2}-\d{2}$/.test(dateString)
    ) {
      const [year, month, day] = dateString.split("-");
      return new Date(year, month - 1, day); // Cria uma data local
    }
    return null;
  };

  const renderDate = (rowData) => {
    const date = parseDate(rowData.date);
    return date ? date.toLocaleDateString("pt-BR") : "-";
  };

  const renderRecordUrl = (rowData) => (
    <a href={`${rowData.recordUrl}`} download>
      <i className="fa-solid fa-download"></i>
    </a>
  );

  // Função para renderizar o footer
  const renderFooter = () => {
    return <div>Total de Chamadas: {filteredCalls.length}</div>;
  };

  const header = renderHeader();

  return (
    <div className="card">
      <NotificationAndDataUpdateAdmin
        getData={getData}
        isDashboard={true}
      />
      <DataTable
        stripedRows
        value={filteredCalls} // Usar registros filtrados
        paginator
        rows={10}
        dataKey="id"
        filters={filters}
        removableSort
        filterDisplay="row"
        loading={loading}
        globalFilterFields={["name", "date", "origin", "destination"]}
        header={header}
        footer={renderFooter()} // Adicionando o footer
        emptyMessage="Nenhum ticket encontrado!"
      >
        <Column field="date" header="Data" sortable body={renderDate} />
        <Column
          field="name"
          header="Nome"
          filter
          showFilterMenu={false}
          filterElement={
            <Dropdown
              value={filters.name.value || null}
              options={names}
              onChange={onNameFilterChange}
              optionLabel="name"
              optionValue="value"
              placeholder="Nome"
              className="p-column-filter"
              showClear
            />
          }
        />
        <Column
          field="origin"
          header="Origem"
          filter
          showFilterMenu={false}
          filterElement={
            <Dropdown
              value={filters.origin.value || null}
              options={origins}
              onChange={onOriginFilterChange}
              optionLabel="name"
              optionValue="value"
              placeholder="Origem"
              className="p-column-filter"
              showClear
            />
          }
        />
        <Column
          field="destination"
          header="Destino"
          filter
          showFilterMenu={false}
          filterElement={
            <Dropdown
              value={filters.destination.value || null}
              options={destinations}
              onChange={onDestinationFilterChange}
              optionLabel="name"
              optionValue="value"
              placeholder="Destino"
              className="p-column-filter"
              showClear
            />
          }
        />
        <Column field="duration" header="Duração" />
        <Column field="recordUrl" header="Gravação" body={renderRecordUrl} />
      </DataTable>
    </div>
  );
}
