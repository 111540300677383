import ApiService from "../ApiService";
class TaskService extends ApiService {
  constructor() {
    super("/tarefa");
  }

  listTasks(startDate, endDate) {
    return this.get("/" + startDate + "/" + endDate);
  }

  listUserTasks(startDate, endDate) {
    return this.get("/agente/" + startDate + "/" + endDate);
  }

  createTask(taskData) {
    return this.post("", taskData);
  }

  startTask(taskId) {
    return this.patch("/iniciar/" + taskId);
  }

  endTask(taskId) {
    return this.patch("/finalizar/" + taskId);
  }

  listUserTasksStatus(startDate, endDate) {
    return this.get("/agentes/status/" + startDate + "/" + endDate);
  }

  userTasksStatus(startDate, endDate) {
    return this.get("/status/" + startDate + "/" + endDate);
  }

  createTasks(tasksData) {
    return this.post("/massa", tasksData);
  }

  deleteTask(id) {
    return this.delete("/"+id);
  }
}
export default TaskService;
