

import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import "../css/UserTable.css";
import TaskService from "../app/service/TaskService";
import NotificationAndDataUpdate from "./NotificationAndDataUpdate";

export default function UserTaskStatuses({ isDarkMode, startDate, endDate }) {
  const taskService = new TaskService();
  const [metrics, setMetrics] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [globalFilterPercentValue, setGlobalFilterPercentValue] = useState("");

  // Filtros separados para cada tabela
  const [filtersStatus, setFiltersStatus] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [filtersPercent, setFiltersPercent] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  // Carregar tema dinâmico (claro ou escuro)
  useEffect(() => {
    const link = document.createElement("link");
    link.id = "theme-link";
    link.rel = "stylesheet";
    link.href = isDarkMode ? "./css/DarkTable.css" : "./css/LightTable.css";
    document.head.appendChild(link);

    return () => {
      const existingLink = document.getElementById("theme-link");
      if (existingLink) {
        document.head.removeChild(existingLink);
      }
    };
  }, [isDarkMode]);

  // Obter os dados e calcular as porcentagens
  const getData = () => {
    if(startDate !== "" && endDate!==""){
      taskService.userTasksStatus(startDate, endDate).then((response) => {
        const data = response.data.map((task) => {
          const total =
            task.pending +
            task.inProgress +
            task.inProgressLate +
            task.delivered +
            task.deliveredLate +
            task.notDelivered;
          return {
            ...task,
            pendingPercent: total
              ? ((task.pending / total) * 100).toFixed(0) + "%"
              : "-",
            inProgressPercent: total
              ? ((task.inProgress / total) * 100).toFixed(0) + "%"
              : "-",
            inProgressLatePercent: total
              ? ((task.inProgressLate / total) * 100).toFixed(0) + "%"
              : "-",
            deliveredPercent: total
              ? ((task.delivered / total) * 100).toFixed(0) + "%"
              : "-",
            deliveredLatePercent: total
              ? ((task.deliveredLate / total) * 100).toFixed(0) + "%"
              : "-",
            notDeliveredPercent: total
              ? ((task.notDelivered / total) * 100).toFixed(0) + "%"
              : "-",
          };
        });
        setMetrics(data);
      });
    }
   
  };

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    getData();
  }, [startDate, endDate]);

  // Alteração no filtro da tabela de status
  const onGlobalFilterStatusChange = (e) => {
    const value = e.target.value;
    setFiltersStatus({
      ...filtersStatus,
      global: { value, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue(value);
  };

  // Alteração no filtro da tabela de porcentagens
  const onGlobalFilterPercentChange = (e) => {
    const value = e.target.value;
    setFiltersPercent({
      ...filtersPercent,
      global: { value, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterPercentValue(value);
  };

  // Cabeçalho da tabela de status
  const headerStatus = () => (
    <div className="flex justify-content-between align-items-center">
      <h2>Status</h2>
      <InputText
        value={globalFilterValue}
        onChange={onGlobalFilterStatusChange}
        placeholder="Pesquisar"
        className="p-inputtext"
      />
    </div>
  );

  // Cabeçalho da tabela de porcentagens
  const headerPercent = () => (
    <div className="flex justify-content-between align-items-center">
      <h2>Status %</h2>
      <InputText
        value={globalFilterPercentValue}
        onChange={onGlobalFilterPercentChange}
        placeholder="Pesquisar"
        className="p-inputtext"
      />
    </div>
  );

  return (
    <>
    <NotificationAndDataUpdate getData={getData} notification={false} taskComponent={true} />
      <div className="card" style={{ marginBottom: "50px" }}>
        <DataTable
          value={metrics}
          filters={filtersStatus}
          paginator
          rows={10}
          dataKey="name"
          header={headerStatus}
          emptyMessage="Nenhum usuário encontrado!"
        >
          <Column field="pending" header="Pendente" />
          <Column field="inProgress" header="Em andamento" />
          <Column field="inProgressLate" header="Em andamento (atraso)" />
          <Column field="delivered" header="Entregue" />
          <Column field="deliveredLate" header="Entregue (atraso)" />
          <Column field="notDelivered" header="Não entregue" />
        </DataTable>
      </div>

      <div className="card" style={{ marginBottom: "50px" }}>
        <DataTable
          value={metrics}
          filters={filtersPercent}
          paginator
          rows={10}
          dataKey="name"
          header={headerPercent}
          emptyMessage="Nenhum usuário encontrado!"
        >
          <Column field="pendingPercent" header="Pendente %" />
          <Column field="inProgressPercent" header="Em andamento %" />
          <Column
            field="inProgressLatePercent"
            header="Em andamento (atraso) %"
          />
          <Column field="deliveredPercent" header="Entregue %" />
          <Column field="deliveredLatePercent" header="Entregue (atraso) %" />
          <Column field="notDeliveredPercent" header="Não entregue %" />
        </DataTable>
      </div>
    </>
  );
}

