import React, { useState } from "react";
import "../css/Task.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "@fortawesome/fontawesome-free/css/all.min.css";
import UserTaskTable from "../component/UserTaskTable";
import { FormControl } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ChangePasswordModal from "../component/ChangePasswordModal";
import UserTaskStatuses from "../component/UserTaskStatuses";
export default function Task({ isDarkMode }) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const toggleModal = () => {
    setShow((prev) => !prev);
  };
  const [startDate, setStartDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const logout = () => {
    localStorage.removeItem("_authenticated_user");
    localStorage.removeItem("_authenticated_user_extension");
    localStorage.removeItem("_authenticated_user_name");
    navigate("/");
  };
  return (
    <div
      style={{ color: !isDarkMode ? "#212529" : "white" }}
      className={`bodyContent ${isDarkMode ? "bg-dark" : ""}`}
      id="taskBody"
    >
      <div className="btn btn-danger logout-button" onClick={logout}>
        <i className="fa-solid fa-right-from-bracket"></i>
      </div>
      <div className="btn btn-warning password-button" onClick={toggleModal}>
        <i className="fa-solid fa-key"></i>
      </div>
      <div id="content-header-task">
        <div className="period-container">
          <p>De</p>
          <FormControl
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className={`${isDarkMode ? "dark-task" : ""}`}
          />
          <p>até</p>
          <FormControl
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className={`${isDarkMode ? "dark-task" : ""}`}
          />
        </div>
      </div>
      <h1>Minhas tarefas</h1>
      <UserTaskTable
        startDate={startDate}
        endDate={endDate}
        isDarkMode={isDarkMode}
      />
      <h1 style={{ marginBottom: "40px" }}>Minhas métricas</h1>
      <UserTaskStatuses
        startDate={startDate}
        endDate={endDate}
        isDarkMode={isDarkMode}
      />
      <ChangePasswordModal
        extension={null}
        adminChange={false}
        isDarkMode={isDarkMode}
        show={show}
        toggle={toggleModal}
      />
    </div>
  );
}
