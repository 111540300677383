import ApiService from "../ApiService";
class UserService extends ApiService {
  constructor() {
    super("/usuario");
  }

  listUsers() {
    return this.get("");
  }

  toggleStatus(extension) {
    return this.patch("/status/" + extension);
  }

  changePassword(extension, credentials) {
    return this.patch("/senha/" + extension, credentials);
  }

  getUser(extension) {
    return this.get("/" + extension);
  }

  createEditUser(endpoint, userData, method) {
    return this[method]("/" + endpoint, userData);
  }

  listEnabledUsers() {
    return this.get("/ativo");
  }
}
export default UserService;
