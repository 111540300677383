import React, { useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import UserService from "../app/service/UserService";

function UserModal({
  show,
  toggleModal,
  edit,
  isDarkMode,
  editExtension,
  getData,
}) {
  const userService = new UserService();
  const [user, setUser] = useState({
    name: "",
    username: "",
    extension: "",
    password: "",
  });

  const handleToggle = () => {
    if (show) {
      setUser({
        name: "",
        username: "",
        extension: "",
        password: "",
      });
    }

    toggleModal();
  };

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    if (edit === true && editExtension !== undefined) {
      userService
        .getUser(editExtension)
        .then((response) => setUser(response.data));
    }
  }, [edit, editExtension]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const saveUser = () => {
    var endpoint = edit ? `editar/${editExtension}` : "criar";
    var method = edit ? "patch" : "post";
    userService
      .createEditUser(endpoint, user, method)
      .then(() => {
        setUser({
          name: "",
          username: "",
          extension: "",
          password: "",
        });
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: error.response.data.error,
        });
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (user.name === "") {
      Swal.fire({
        icon: "error",
        title: "Campo obrigatório",
        text: "O campo nome não pode estar vazio.",
      });
    } else if (user.username === "") {
      Swal.fire({
        icon: "error",
        title: "Campo obrigatório",
        text: "O campo usuário não pode estar vazio.",
      });
    } else if (!edit && user.password === "") {
      Swal.fire({
        icon: "error",
        title: "Campo obrigatório",
        text: "O campo senha não pode estar vazio.",
      });
    } else if (user.extension === "") {
      Swal.fire({
        icon: "error",
        title: "Campo inválido",
        text: "O campo número do ramal deve ser um número válido.",
      });
    } else {
      saveUser(user);
      toggleModal();
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleToggle}
      size="lg"
      data-bs-theme={isDarkMode ? "dark" : "light"}
      style={{ color: isDarkMode ? "white" : "" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Usuário</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Control type="hidden" value={user.id} />
          <Form.Group controlId="formName" className="input-user-modal">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={user.name}
              onChange={handleChange}
              placeholder="Insira o nome"
            />
          </Form.Group>
          <div className="row">
            <div className="col">
              <Form.Group controlId="formUsername" className="input-user-modal">
                <Form.Label>Usuário</Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  value={user.username}
                  onChange={handleChange}
                  placeholder="Insira o usuário"
                />
              </Form.Group>
            </div>
            {!edit && (
              <div className="col">
                <Form.Group
                  controlId="formPassword"
                  className="input-user-modal"
                >
                  <Form.Label>Senha</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={user.password || ""}
                    onChange={handleChange}
                    placeholder="Insira a senha"
                  />
                </Form.Group>
              </div>
            )}
          </div>

          <div className="row">
            <div className="col">
              <Form.Group
                controlId="formExtensionUrl"
                className="input-user-modal"
              >
                <Form.Label>Ramal</Form.Label>
                <Form.Control
                  type="text"
                  name="extension"
                  value={user.extension}
                  onChange={handleChange}
                  placeholder="Insira o ramal"
                />
              </Form.Group>
            </div>
          </div>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Enviar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default UserModal;
