import React from "react";
import SideBar from "../component/SideBar";
import UserTable from "../component/UserTable";
import NotificationAndDataUpdateAdmin from "../component/NotificationAndDataUpdateAdmin";

function Users({isDarkMode}){

    return(
        <div
        className="body"
        data-bs-theme={isDarkMode ? "dark" : "light"}
        style={{ color: !isDarkMode ? "#212529" : "white" }}
      >
              <NotificationAndDataUpdateAdmin notification={true}/>

        <SideBar isDarkMode={isDarkMode} selected={"users"}/>
        <div className={`bodyContent ${isDarkMode ? "bg-dark" : ""}`}>
          <div id="content-header">
            <h1>Usuários</h1>
          </div>
          <UserTable isDarkMode={isDarkMode}/>
        </div>
      </div>
    );
}

export default Users;