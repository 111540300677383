import React, { useState } from "react";
import SideBar from "../component/SideBar";
import { FormControl } from "react-bootstrap";
import "../css/AdminTask.css";
import TaskTable from "../component/TaskTable";
import AdminTaskTable from "../component/AdminTaskTables";
import NotificationAndDataUpdateAdmin from "../component/NotificationAndDataUpdateAdmin";

function AdminTask({ isDarkMode }) {

  const [startDate, setStartDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));

  return (
    <div
      className="body"
      data-bs-theme={isDarkMode ? "dark" : "light"}
      style={{ color: !isDarkMode ? "#212529" : "white" }}
    >
      <NotificationAndDataUpdateAdmin notification={true}/>
      <SideBar isDarkMode={isDarkMode} selected={"tarefasAdm"} />
      <div className={`bodyContent ${isDarkMode ? "bg-dark" : ""}`}>
        <div id="content-header">
          <div className="titleContainer">
            <h1>Tarefas</h1>
          </div>
          <div className="period-container">
            <p>De</p>
            <FormControl
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className={`${isDarkMode ? "dark" : ""}`}
            />
            <p>até</p>
            <FormControl
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className={`${isDarkMode ? "dark" : ""}`}
            />
          </div>
        </div>
        <TaskTable
          startDate={startDate}
          endDate={endDate}
          isDarkMode={isDarkMode}
        />
        <h1 style={{marginBottom: '40px'}}>Métricas</h1>
        <AdminTaskTable isDarkMode={isDarkMode} startDate={startDate} endDate={endDate}/>
      </div>
    </div>
  );
}

export default AdminTask;
