import React, { useState } from "react";
import "../css/SideBar.css";
import Logo from "../assets/images/logo.png";
import WhiteLogo from "../assets/images/whiteLogo.png";
import { useNavigate } from "react-router-dom";
import ChangePasswordModal from "./ChangePasswordModal";
function SideBar({ isDarkMode, selected }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const [show, setShow] = useState(false);

  const toggleModal = () => {
    setShow((prev) => !prev);
  };

  const toggleSideBar = () => {
    setIsOpen((prev) => !prev);
  };

  const redirect = (destination) => {
    navigate("/" + destination);
  };

  const logout = () => {
    localStorage.removeItem("_authenticated_user");
    localStorage.removeItem("_authenticated_user_extension");
    localStorage.removeItem("_authenticated_user_name");
    navigate("/");
  };

  return (
    <>
      <div
        className="toggleSideBarButton"
        style={{ left: isOpen ? "13.7%" : "-20px", color: "white" }}
        onClick={toggleSideBar}
      >
        {isOpen ? (
          <i className="fa-solid fa-arrow-left"></i>
        ) : (
          <i className="fa-solid fa-arrow-right"></i>
        )}
      </div>
      <div
        className="sidebar"
        style={{
          width: isOpen ? "15%" : "0",
          backgroundColor: isDarkMode ? "#161D21" : "white",
        }}
      >
        <img src={isDarkMode ? WhiteLogo : Logo} className="logo" alt=""></img>
        <hr />
        <div className="pages">
          <div
            onClick={() => redirect("dashboard")}
            className={`page ${
              selected === "dashboard" ? "bg-info bg-gradient" : ""
            }`}
          >
            <i className="fa-solid fa-gauge-high "></i>
            <div className="pageTitle">Dashboard</div>
          </div>
          <div
            onClick={() => redirect("usuarios")}
            className={`page ${
              selected === "users" ? "bg-info bg-gradient" : ""
            }`}
          >
            <i className="fa-solid fa-users"></i>
            <div className="pageTitle">Usuários</div>
          </div>
          <div
            onClick={() => redirect("tarefasAdm")}
            className={`page ${
              selected === "tarefasAdm" ? "bg-info bg-gradient" : ""
            }`}
          >
            <i className="fa-solid fa-list-check"></i>{" "}
            <div className="pageTitle">Tarefas</div>
          </div>
        </div>
        <div className="user">
          <hr />
          <div className="dropdown">
            <i className="fa-solid fa-user"></i>
            <div
              className="dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {localStorage.getItem("_authenticated_user_name")}
            </div>
            <ul className="dropdown-menu">
              <li onClick={toggleModal}>
                <div className="dropdown-item">Alterar senha</div>
              </li>
              <li onClick={logout}>
                <div className="dropdown-item">Sair</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ChangePasswordModal
        extension={null}
        adminChange={false}
        isDarkMode={isDarkMode}
        show={show}
        toggle={toggleModal}
      />
    </>
  );
}
export default SideBar;
