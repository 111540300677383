import React, { useState, useEffect } from "react";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { MultiSelect } from "primereact/multiselect";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import "../css/TicketTable.css";
import MovideskService from "../app/service/MovideskService";
import NotificationAndDataUpdateAdmin from "./NotificationAndDataUpdateAdmin";

export default function TicketTable({ startDate, endDate, isDarkMode }) {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    owner: { value: [], matchMode: FilterMatchMode.IN },
    creator: { value: null, matchMode: FilterMatchMode.EQUALS },
    client: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [loading, setLoading] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [representatives, setRepresentatives] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [creators, setCreators] = useState([]);
  const [clients, setClients] = useState([]);

  const movideskService = new MovideskService();

  useEffect(() => {
    const link = document.createElement("link");
    link.id = "theme-link";
    link.rel = "stylesheet";
    if (isDarkMode) {
      link.href = "./css/DarkTable.css";
    } else {
      link.href = "./css/LightTable.css";
    }
    document.head.appendChild(link);

    return () => {
      const existingLink = document.getElementById("theme-link");
      if (existingLink) {
        document.head.removeChild(existingLink);
      }
    };
  }, [isDarkMode]);

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    getData();
  }, [startDate, endDate]);

  const getData = () => {
    if ((startDate !== "") & (endDate !== "")) {
      movideskService.getTickets(startDate, endDate).then((response) => {
        const data = response.data;
        setCustomers(data);
        setFilteredCustomers(data); // Inicialmente, todos os clientes são visíveis
        setRepresentatives(getUniqueOwners(data));
        setStatuses(getUniqueStatuses(data));
        setCreators(getUniqueCreators(data));
        setClients(getUniqueClients(data));
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    // Atualiza os clientes filtrados quando os filtros mudam
    const filtered = customers.filter((customer) => {
      return Object.keys(filters).every((key) => {
        if (filters[key].value == null || filters[key].value.length === 0)
          return true;
        if (Array.isArray(filters[key].value)) {
          return filters[key].value.includes(customer[key]);
        }
        return String(customer[key])
          .toLowerCase()
          .includes(String(filters[key].value).toLowerCase());
      });
    });
    setFilteredCustomers(filtered);
  }, [filters, customers]);


  const getUniqueOwners = (data) => {
    const owners = [...new Set(data.map((item) => item.owner))];
    return owners.map((owner) => ({ name: owner, value: owner }));
  };

  const getUniqueStatuses = (data) => {
    const statuses = [...new Set(data.map((item) => item.status))];
    return statuses.map((status) => ({ name: status, value: status }));
  };

  const getUniqueCreators = (data) => {
    const creators = [...new Set(data.map((item) => item.creator))];
    return creators.map((creator) => ({ name: creator, value: creator }));
  };

  const getUniqueClients = (data) => {
    const clients = [...new Set(data.map((item) => item.client))];
    return clients
      .sort((a, b) => a.localeCompare(b)) // Ordena os clientes alfabeticamente
      .map((client) => ({ name: client, value: client }));
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setFilters({
      ...filters,
      global: { value, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue(value);
  };

  const onStatusFilterChange = (e) => {
    const value = e.value || null;
    setFilters({
      ...filters,
      status: { value, matchMode: FilterMatchMode.EQUALS },
    });
  };

  const onOwnerFilterChange = (e) => {
    const value = e.value || [];
    setFilters({
      ...filters,
      owner: { value, matchMode: FilterMatchMode.IN },
    });
  };

  const onCreatorFilterChange = (e) => {
    const value = e.value || null;
    setFilters({
      ...filters,
      creator: { value, matchMode: FilterMatchMode.EQUALS },
    });
  };

  const onClientFilterChange = (e) => {
    const value = e.value || null;
    setFilters({
      ...filters,
      client: { value, matchMode: FilterMatchMode.EQUALS },
    });
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <h3 style={{ margin: "0" }}>Tickets Movidesk</h3>
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Pesquisar"
          className="p-inputtext"
        />
      </div>
    );
  };

  const statusBodyTemplate = (rowData) => {
    const severity = rowData.status === "Resolvido" ? "success" : "warning";
    return <Tag value={rowData.status} severity={severity} />;
  };

  const parseDate = (dateString) => {
    if (
      typeof dateString === "string" &&
      /^\d{4}-\d{2}-\d{2}$/.test(dateString)
    ) {
      const [year, month, day] = dateString.split("-");
      return new Date(year, month - 1, day); // Cria uma data local
    }
    return null;
  };

  const renderStartDate = (rowData) => {
    const date = parseDate(rowData.startDate);
    return date ? date.toLocaleDateString("pt-BR") : "-";
  };

  const renderEndDate = (rowData) => {
    const date = parseDate(rowData.endDate);
    return date ? date.toLocaleDateString("pt-BR") : "-";
  };

  // Função para renderizar o footer
  const renderFooter = () => {
    return <div>Total de Tickets: {filteredCustomers.length}</div>;
  };

  const header = renderHeader();

  const idBody = (rowData) => {
    var link =
      "https://atendimento.solutionsvoip.com.br/Ticket/Edit/" + rowData.id;

    return (
      <a href={link} rel="noreferrer" target="_blank">
        {rowData.id}
      </a>
    );
  };

  return (
    <div className="card" style={{ marginBottom: "50px" }}>
      <NotificationAndDataUpdateAdmin getData={getData} isDashboard={true} />
      <DataTable
        stripedRows
        value={filteredCustomers} // Usar registros filtrados
        paginator
        rows={10}
        dataKey="id"
        filters={filters}
        removableSort
        filterDisplay="row"
        loading={loading}
        globalFilterFields={["id", "status", "creator", "owner", "client"]}
        header={header}
        footer={renderFooter()} // Adicionando o footer
        emptyMessage="Nenhum ticket encontrado!"
      >
        <Column field="id" header="ID" body={idBody} sortable />
        <Column
          field="status"
          header="Status"
          body={statusBodyTemplate}
          filter
          showFilterMenu={false}
          filterElement={
            <Dropdown
              value={filters.status.value || null}
              options={statuses}
              onChange={onStatusFilterChange}
              optionLabel="name"
              optionValue="value"
              placeholder="Status"
              className="p-column-filter"
              showClear
            />
          }
        />
        <Column
          field="startDate"
          header="Abertura"
          sortable
          body={renderStartDate}
        />
        <Column
          field="endDate"
          header="Fechamento"
          sortable
          body={renderEndDate}
        />
        <Column
          field="owner"
          header="Atendente"
          filter
          showFilterMenu={false}
          filterElement={
            <MultiSelect
              value={filters.owner.value || []}
              options={representatives}
              onChange={onOwnerFilterChange}
              optionLabel="name"
              optionValue="value"
              placeholder="Atendente(s)"
              className="p-column-filter"
            />
          }
        />
        <Column
          field="creator"
          header="Criador"
          filter
          showFilterMenu={false}
          filterElement={
            <Dropdown
              value={filters.creator.value || null}
              options={creators}
              onChange={onCreatorFilterChange}
              optionLabel="name"
              optionValue="value"
              placeholder="Criador"
              className="p-column-filter"
              showClear
            />
          }
        />
        <Column
          field="client"
          header="Cliente"
          filter
          showFilterMenu={false}
          filterElement={
            <Dropdown
              value={filters.client.value || null}
              options={clients}
              onChange={onClientFilterChange}
              optionLabel="name"
              optionValue="value"
              placeholder="Cliente"
              className="p-column-filter"
              showClear
            />
          }
        />
      </DataTable>
    </div>
  );
}
