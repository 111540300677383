import React, { useState, useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./css/App.css";
import MyRoutes from "./Routes";

function App() {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    return localStorage.getItem("isDarkMode") === "true";
  });

  useEffect(() => {
    localStorage.setItem("isDarkMode", isDarkMode);
    document.body.className = isDarkMode ? "dark-mode" : "";
  }, [isDarkMode]);

  const handleButtonClick = () => {
    setIsDarkMode((prevIsDarkMode) => !prevIsDarkMode);
  };

  return (
    <div>
      <label className="switch" id="toggleTheme">
        <input
          type="checkbox"
          checked={isDarkMode}
          onChange={handleButtonClick}
        />
        <span className="slider round"></span>
      </label>
      <MyRoutes isDarkMode={isDarkMode} />
      <i
        className="fa-solid fa-moon"
        id="moon"
        style={{ color: !isDarkMode ? "#161D21" : "white" }}
        onClick={handleButtonClick}
      ></i>
      <i
        style={{ color: isDarkMode ? "#161D21" : "white" }}
        className="fa-solid fa-sun"
        id="sun"
        onClick={handleButtonClick}
      ></i>
    </div>
  );
}

export default App;
