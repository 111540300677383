import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import TaskService from "../app/service/TaskService";
import { Button } from "react-bootstrap";
import TaskModal from "./TaskModal";
import NotificationAndDataUpdateAdmin from "./NotificationAndDataUpdateAdmin";
import TasksModal from "./TasksModal";
import Swal from "sweetalert2";

export default function TaskTable({ startDate, endDate, isDarkMode }) {
  const [tasks, setTasks] = useState([]);
  const taskService = new TaskService();
  const [show, setShow] = useState(false);
  const [showTasksModal, setShowTasksModal] = useState(false);
  const createTask = (taskData) => {
    taskService.createTask(taskData).then(() => {
      toggleModal();
    });
  };

  const toggleModal = () => {
    setShow((prev) => !prev);
  };

  const toggleTasksModal = () => {
    setShowTasksModal((prev) => !prev);
  };

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    getData();
  }, [startDate, endDate]);

  const getData = () => {
    if (startDate !== "" && endDate !== "") {
      taskService.listTasks(startDate, endDate).then((response) => {
        setTasks(response.data);
      });
    }
  };

  const createTasks = (requestBody) => {
    taskService.createTasks(requestBody).then(() => {
      getData();
      setShowTasksModal(false);
    });
  };

  const header = () => {
    return (
      <div className="d-flex justify-content-between">
        <Button onClick={toggleModal}>Criar tarefa</Button>
        <Button variant="success" onClick={toggleTasksModal}>
          Criar tarefas
        </Button>
      </div>
    );
  };

  const formatDateTime = (date) => {
    if (!date) return "-";
    return new Date(date).toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const creationBody = (rowData) => {
    return <>{formatDateTime(rowData.creationDate)}</>;
  };

  const deadlineBody = (rowData) => {
    return <>{formatDateTime(rowData.deadline)}</>;
  };

  const startDateBody = (rowData) => {
    return <>{formatDateTime(rowData.startDate)}</>;
  };

  const closedInDateBody = (rowData) => {
    return <>{formatDateTime(rowData.closedInDate)}</>;
  };

  const ownersBody = (rowData) => {
    return <>{rowData.owners.join(", ")}</>;
  };

  const annotationBody = (rowData) => {
    return rowData.annotation ? rowData.annotation : "-";
  };

  const deleteBody = (rowData) => {
    return (
      <Button variant="danger" onClick={() => handleDeleteTask(rowData.id)}>
        <i className="fa-solid fa-trash"></i>
      </Button>
    );
  };

  const handleDeleteTask = (id) => {
    Swal.fire({
      icon: "warning",
      title: "Deseja mesmo excluir a tarefa?",
      showDenyButton: true,
      confirmButtonText: "Sim",
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTask(id);
      }
    });
  };

  const deleteTask = (id) => {
    taskService
      .deleteTask(id)
      .then(() => {
        getData();
      })
      .catch();
  };

  return (
    <div className="card" style={{ marginBottom: "50px" }}>
      <NotificationAndDataUpdateAdmin taskComponent={true} getData={getData} />
      <DataTable
        removableSort
        header={header}
        value={tasks}
        paginator
        rows={10}
        dataKey="id"
        emptyMessage="Nenhuma tarefa encontrada!"
      >
        <Column field="title" header="Tarefa" sortable/>
        <Column field="creationDate" header="Criação" body={creationBody} />
        <Column field="deadline" header="Prazo" body={deadlineBody} />
        <Column field="startDate" header="Início" body={startDateBody} />
        <Column
          field="closedInDate"
          header="Conclusão"
          body={closedInDateBody}
        />
        <Column field="annotation" header="Observações" body={annotationBody} />
        <Column field="status" header="Status" sortable/>
        <Column field="creator" header="Criador" sortable/>
        <Column
          field="owners"
          header="Responsável(eis)"
          body={ownersBody}
          sortable
        />
        <Column header="Excluir" body={deleteBody} />
      </DataTable>
      <TaskModal
        show={show}
        isDarkMode={isDarkMode}
        toggleModal={toggleModal}
        createTask={createTask}
      />
      <TasksModal
        show={showTasksModal}
        isDarkMode={isDarkMode}
        toggleModal={toggleTasksModal}
        createTasks={createTasks}
      />
    </div>
  );
}
