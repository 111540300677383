import React, { useEffect, useState } from "react";
import { Button, Form, FormControl, Modal } from "react-bootstrap";
import { MultiSelect } from "primereact/multiselect";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css"; // Ensure this path is correct
import "../css/TaskModal.css"; // Ensure this path is correct
import UserService from "../app/service/UserService"; // Ensure this path is correct
export default function TaskModal({ show, isDarkMode, toggleModal, createTask }) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [title, setTitle] = useState("");
  const [deadline, setDeadline] = useState("");
  const userService = new UserService();
  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    getUsers();
  }, []);

  useEffect(() => {
    const link = document.createElement("link");
    link.id = "theme-link";
    link.rel = "stylesheet";
    link.href = isDarkMode ? "./css/DarkTable.css" : "./css/LightTable.css";
    document.head.appendChild(link);

    return () => {
      const existingLink = document.getElementById("theme-link");
      if (existingLink) {
        document.head.removeChild(existingLink);
      }
    };
  }, [isDarkMode]);

  const getUsers = async () => {
    try {
      const response = await userService.listEnabledUsers();
      setOptions(
        response.data.map((user) => ({
          label: user.name,
          value: user.extension,
        }))
      );
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleCreateTask = () => {
    var taskData = {
      title: title,
      deadline: deadline,
      owners: selectedOptions,
    };
    createTask(taskData);
  };

  return (
    <Modal
      show={show}
      size="lg"
      data-bs-theme={isDarkMode ? "dark" : "light"}
      style={{ color: isDarkMode ? "white" : "" }}
      onHide={toggleModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Tarefa</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="taskName">
            <Form.Label>Nome</Form.Label>
            <FormControl
              placeholder="Insira o nome da tarefa"
              style={{ height: "43px" }}
              className="modal-input"
              value={title}
              onChange={(e)=> setTitle(e.target.value)}
            />
          </Form.Group>
          <div className="row">
            <div className="col">
              <Form.Group controlId="deadline">
                <Form.Label>Prazo</Form.Label>
                <FormControl
                  type="datetime-local"
                  placeholder="Insira o prazo"
                  style={{ height: "43px" }}
                  className="modal-input"
                  value={deadline}
                  onChange={(e)=> setDeadline(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group
                controlId="multiSelect"
                className="d-flex flex-column"
              >
                <Form.Label>Responsável(eis)</Form.Label>
                <MultiSelect
                  className="custom-multiselect"
                  value={selectedOptions}
                  options={options}
                  onChange={(e) => setSelectedOptions(e.value)}
                  placeholder="Selecione opções"
                  display="chip"
                />
              </Form.Group>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer><Button onClick={handleCreateTask}>Enviar</Button></Modal.Footer>
    </Modal>
  );
}
