import React, { useState, useEffect, useMemo } from "react";
import { Button, FormControl } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import Swal from "sweetalert2";
import SideBar from "../component/SideBar";
import TicketTable from "../component/TicketTable";
import PabxCallTable from "../component/PabxCallTable";
import CallService from "../app/service/CallService";
import MovideskService from "../app/service/MovideskService";
import DataBaseService from "../app/service/DataBaseService";
import "../css/Dashboard.css";
import { Chart, ArcElement, Legend, Tooltip, Colors, CategoryScale, LinearScale, BarElement } from "chart.js";
import NotificationAndDataUpdate from "../component/NotificationAndDataUpdateAdmin";

// Register Chart.js components
Chart.register(ArcElement, Colors, Legend, Tooltip, CategoryScale, LinearScale, BarElement);
Chart.defaults.color = "#6c757d";

const Dashboard = ({ isDarkMode }) => {
  // Services initialization
  const callService = useMemo(() => new CallService(), []);
  const movideskService = useMemo(() => new MovideskService(), []);
  const dataBaseService = useMemo(() => new DataBaseService(), []);

  // State management
  const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10));
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const [userCallData, setUserCallData] = useState([]);
  const [userCallLabel, setUserCallLabel] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isValidDateRange, setIsValidDateRange] = useState(true);
  const [movideskTicketData, setMovideskTicketData] = useState([]);
  const [movideskTicketLabel, setMovideskTicketLabel] = useState([]);
  const [ticketsStatusData, setTicketsStatusData] = useState([]);
  const [ticketsStatusLabel, setTicketsStatusLabel] = useState([]);

  // Chart.js options
  const commonOptions = {
    plugins: {
      legend: { display: false },
    },
    responsive: true,
    maintainAspectRatio: true,
  };

  const optionsSolo = {
    plugins: {
      legend: { display: true },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: false,
        min: 0,
        max: 100,
        ticks: { stepSize: 10},
      },
    },
  };

  const statusOptions = {
    ...commonOptions,
    scales: {
      y: {
        beginAtZero: false,
        min: 0,
        max: 15,
        ticks: { stepSize: 5 },
      },
    },
  };

  // Data fetching
  const fetchData = async () => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      setIsValidDateRange(start <= end);
      
      if (isValidDateRange) {
        setLoading(true);

        try {
          const [callResponse, movideskResponse, statusResponse] = await Promise.all([
            callService.getCallGraphic(startDate, endDate),
            movideskService.getTicketsGraphic(startDate, endDate),
            movideskService.getStatusGraphic(startDate, endDate),
          ]);
          setUserCallData(callResponse.data.data);
          setUserCallLabel(callResponse.data.label);

          setMovideskTicketData(movideskResponse.data.datasets);
          setMovideskTicketLabel(movideskResponse.data.labels);

          setTicketsStatusData(statusResponse.data.data);
          setTicketsStatusLabel(statusResponse.data.label);



        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }
    } else {
      setIsValidDateRange(false);
    }
  };

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    fetchData();
  }, [startDate, endDate, isValidDateRange, movideskService, callService]);


  const barColors = [
    "rgba(54, 162, 235, 0.6)",
    "rgba(255, 99, 132, 0.6)",
    "rgba(255, 206, 86, 0.6)",
    "rgba(75, 192, 192, 0.6)",
    "rgba(153, 102, 255, 0.6)",
    "rgba(255, 159, 64, 0.6)",
    "rgba(201, 203, 207, 0.6)",
    "rgba(255, 127, 80, 0.6)",
    "rgba(60, 179, 113, 0.6)",
    "rgba(123, 104, 238, 0.6)",
    "rgba(0, 191, 255, 0.6)",
    "rgba(255, 20, 147, 0.6)",
    "rgba(50, 205, 50, 0.6)",
    "rgba(219, 112, 147, 0.6)",
    "rgba(30, 144, 255, 0.6)",
    "rgba(255, 105, 180, 0.6)",
    "rgba(107, 142, 35, 0.6)",
    "rgba(186, 85, 211, 0.6)",
    "rgba(127, 255, 212, 0.6)",
    "rgba(138, 43, 226, 0.6)",
  ];

  const callData = {
    labels: userCallLabel,
    datasets: [{
      label: "Chamadas",
      data: userCallData,
      backgroundColor: barColors,
      borderWidth: 1,
    }],
  };

  const ticketsStatus = {
    labels: ticketsStatusLabel,
    datasets: [{
      label: "Tickets",
      data: ticketsStatusData,
      backgroundColor: barColors,
      borderWidth: 1,
    }],
  };

  const ticketData = {
    labels: movideskTicketLabel,
    datasets: movideskTicketData.map((dataset, index) => ({
      ...dataset,
      data: dataset.data || [],
    })),
  };

  return (
    <div className="body" data-bs-theme={isDarkMode ? "dark" : "light"} style={{ color: isDarkMode ? "white" : "#212529" }}>
      <NotificationAndDataUpdate getData={fetchData} isDashboard={true} notification={true}/>
      <SideBar isDarkMode={isDarkMode} selected="dashboard" />
      <div className={`bodyContent ${isDarkMode ? "bg-dark" : ""}`}>
        <div id="content-header">
          <div className="titleContainer">
            <h1>Dashboard</h1>
            <Button onClick={() => dataBaseService.refreshDataBase().then(() =>
              Swal.fire({
                icon: "success",
                title: "Sucesso",
                text: "Atualização dos bancos solicitada, você será avisado(a) após a conclusão",
              })
            )}>
              <i className="fa-solid fa-database"></i>
            </Button>
          </div>
          <div className="period-container">
            <p>De</p>
            <FormControl
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className={`${isDarkMode ? "dark" : ""}`}
            />
            <p>até</p>
            <FormControl
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className={`${isDarkMode ? "dark" : ""}`}
            />
          </div>
        </div>

        {!isValidDateRange ? (
          <p style={{ color: "red" }}>
            Data de início deve ser menor ou igual à data de término.
          </p>
        ) : loading ? (
          <p>Carregando...</p>
        ) : (
          <>
            <div className="graphicRow">
              <div style={{ width: '100%', height: '450px', padding: '30px' }} className={`graphic-container ${isDarkMode ? "dark" : ""}`}>
                <h6>Tickets Movidesk</h6>
                <Bar data={ticketData} options={optionsSolo} />
              </div>
            </div>
            <div className="graphicRow">
              <div className={`graphic-container ${isDarkMode ? "dark" : ""}`}>
                <h6>Status Tickets</h6>
                <Bar data={ticketsStatus} options={statusOptions} />
              </div>
              <div className={`graphic-container ${isDarkMode ? "dark" : ""}`}>
                <h6>Chamadas PABX</h6>
                <Bar data={callData} options={commonOptions} />
              </div>
            </div>
          </>
        )}

        <TicketTable startDate={startDate} endDate={endDate} isDarkMode={isDarkMode} />
        <PabxCallTable startDate={startDate} endDate={endDate} isDarkMode={isDarkMode} />
      </div>
    </div>
  );
};

export default Dashboard;
