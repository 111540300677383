import ApiService from "../ApiService";
class DataBaseService extends ApiService {
  constructor() {
    super("/banco");
  }

  refreshDataBase() {
    return this.get("/refresh");
  }
}
export default DataBaseService;
