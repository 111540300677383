import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import TaskService from "../app/service/TaskService";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import NotificationAndDataUpdate from "./NotificationAndDataUpdate";

export default function UserTaskTable({ startDate, endDate, isDarkMode }) {
  const [tasks, setTasks] = useState([]);
  const taskService = new TaskService();

  useEffect(() => {
    const link = document.createElement("link");
    link.id = "theme-link";
    link.rel = "stylesheet";
    if (isDarkMode) {
      link.href = "./css/DarkTable.css";
    } else {
      link.href = "./css/LightTable.css";
    }
    document.head.appendChild(link);

    return () => {
      const existingLink = document.getElementById("theme-link");
      if (existingLink) {
        document.head.removeChild(existingLink);
      }
    };
  }, [isDarkMode]);

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    getData();
  }, [startDate, endDate]);

  const getData = () => {
    if (startDate !== "" && endDate !== "") {
      taskService.listUserTasks(startDate, endDate).then((response) => {
        setTasks(response.data);
      });
    }
  };

  const formatDateTime = (date) => {
    if (!date) return "-";
    return new Date(date).toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const creationBody = (rowData) => {
    return <>{formatDateTime(rowData.creationDate)}</>;
  };

  const deadlineBody = (rowData) => {
    return <>{formatDateTime(rowData.deadline)}</>;
  };

  const startDateBody = (rowData) => {
    return <>{formatDateTime(rowData.startDate)}</>;
  };

  const closedInDateBody = (rowData) => {
    return <>{formatDateTime(rowData.closedInDate)}</>;
  };

  const annotationBody = (rowData) => {
    return rowData.annotation ? rowData.annotation : "-";
  };

  const actionBody = (rowData) => {
    if (rowData.status === "Pendente") {
      return (
        <Button onClick={() => startTask(rowData.id)}>Iniciar tarefa</Button>
      );
    } else if (rowData.status === "Em andamento") {
      return (
        <Button variant="danger" onClick={() => endTask(rowData.id)}>
          Finalizar tarefa
        </Button>
      );
    } else if (rowData.status === "Em andamento (atraso)") {
      return (
        <Button onClick={() => endTask(rowData.id)}>
          Finalizar tarefa atrasada
        </Button>
      );
    } else {
      return "";
    }
  };

  const startTask = (taskId) => {

    Swal.fire({
      icon: "warning",
      title: "Deseja mesmo iniciar a tarefa?",
      showDenyButton: true,
      confirmButtonText: "Sim",
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        taskService.startTask(taskId).catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Erro",
            text: error.response.data.error,
            confirmButtonText: "OK",
          }).then(() => getData());
        });
      }
    });
  };

  const endTask = (taskId) => {
    Swal.fire({
      icon: "warning",
      title: "Deseja mesmo finalizar a tarefa?",
      showDenyButton: true,
      confirmButtonText: "Sim",
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        taskService.endTask(taskId).catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Erro",
            text: error.response.data.error,
            confirmButtonText: "OK",
          }).then(() => getData());
        });
      }
    });
  };

  return (
    <div className="card" style={{ marginBottom: "50px" }}>
      <NotificationAndDataUpdate
        getData={getData}
        notification={true}
        taskComponent={true}
      />
      <DataTable
        value={tasks}
        paginator
        rows={5}
        dataKey="id"
        emptyMessage="Nenhuma tarefa encontrada!"
        removableSort
      >
        <Column field="title" header="Tarefa" />
        <Column field="creationDate" header="Criação" body={creationBody} />
        <Column field="deadline" header="Prazo" body={deadlineBody} />
        <Column field="startDate" header="Início" body={startDateBody} />
        <Column
          field="closedInDate"
          header="Conclusão"
          body={closedInDateBody}
        />
        <Column field="annotation" header="Observações" body={annotationBody} />
        <Column field="status" header="Status" sortable />
        <Column header="Ação" body={actionBody} />
      </DataTable>
    </div>
  );
}
