import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
  } from "react-router-dom";
  import Dashboard from "./view/Dashboard";
  import AuthService from "./app/service/AuthService";
  import Login from "./view/Login";
  import Users from "./view/Users";
  import AdminTask from "./view/AdminTask";
import Task from "./view/Task";

export default function MyRoutes({isDarkMode}){
    const authService = new AuthService();

    const ProtectedRoute = ({ element, requiredRole }) => {
      return authService.isUserAuthenticated(requiredRole) ? (
        element
      ) : (
        <Navigate to="/" replace />
      );
    };

    return(
        <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute
                requiredRole="ADMIN"
                element={<Dashboard isDarkMode={isDarkMode} />}
              />
            }
          />
          <Route
            path="/usuarios"
            element={
              <ProtectedRoute
                element={<Users isDarkMode={isDarkMode} />}
                requiredRole="ADMIN"
              />
            }
          />
          <Route
            path="/tarefasAdm"
            element={
              <ProtectedRoute
                requiredRole="ADMIN"
                element={<AdminTask isDarkMode={isDarkMode} />}
              />
            }
          />
          <Route
            path="/tarefas"
            element={
              <ProtectedRoute
                requiredRole="USER"
                element={<Task isDarkMode={isDarkMode} />}
              />
            }
          />
        </Routes>
      </Router>

    );
}